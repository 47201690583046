
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonSpinner, IonIcon, modalController, menuController } from "@ionic/vue";
import { ref, computed } from "vue";

import { refresh, menu, checkmarkCircle, arrowForward } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "../services/utils";
import { openToast } from "../services/toast";

import apiAutomezzi from "../services/automezzi";
import apiSondaggi from "../services/sondaggi";

import PrenotazioneDetail from "../components/PrenotazioneAutomezzoDetail.vue";
import ModalNuovaPrenotazione from "../components/ModalNuovaPrenotazione.vue";

export default {
    name: "Sondaggi",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem("userPlanInfo"));
        const dipendenteID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;
        const loading = ref(false);
        const prenotazioni = ref([]);
        const openMenu = () => {
            menuController.open("app-menu");
        };
        const dataPrenotazione = computed(() => {
            return (dataInizio, dataFine) => {
                const inizio = moment(dataInizio).format("DD/MM/YYYY");
                let fine = null;
                if (dataFine) {
                    fine = moment(dataFine).format("DD/MM/YYYY");
                }
                if (inizio == fine) {
                    return `${inizio}`;
                } else if (!fine) {
                    return `Dal ${inizio}`;
                } else {
                    return `Dal ${inizio} al ${fine}`;
                }
            };
        });

        /**
         * Get all automezzi from the server (refresh icon)
         */
        async function loadPrenotazioni() {
            loading.value = true;
            try {
                prenotazioni.value = await apiAutomezzi.getPrenotazioniAutomezzi(dipendenteID);
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle prenotazioni automezzi", "toast_danger");
            }
            loading.value = false;
        }
        loadPrenotazioni();

        /**
         * Open prenotazione automezzo detail page
         */
        async function openDetailModal(prenotazioneDetail) {
            //console.log(prenotazioneDetail);
            const modal = await modalController.create({
                component: PrenotazioneDetail,
                componentProps: {
                    data: prenotazioneDetail,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail);
                    //Cerco prenotazione appena modificata (detail non è undefined se ho modificato prenotazione, ho chiuso il form, aggiornato il dettaglio per vedere i dati modificati e poi chiuso anche il dettaglio)
                    const editedPrenotazione = detail.data;
                    const editedIndex = prenotazioni.value.findIndex(
                        (prenotazione) => prenotazione.prenotazioni_automezzi_id == editedPrenotazione.prenotazioni_automezzi_id
                    );
                    if (editedIndex && editedPrenotazione) {
                        prenotazioni.value[editedIndex] = editedPrenotazione;
                    }
                }
            });
            return modal.present();
        }

        /**
         * Open modal to create new prenotazione
         */
        async function openModal() {
            const modal = await modalController.create({
                component: ModalNuovaPrenotazione,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    prenotazioni.value = [detail.data, ...prenotazioni.value];
                    openToast("Nuova prenotazione inserita", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Load all sondaggi
         */
        const sondaggi = ref([]);
        async function loadSondaggi() {
            loading.value = true;
            try {
                sondaggi.value = await apiSondaggi.getSondaggi();
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei sondaggi", "toast_danger");
            }
            loading.value = false;
        }
        loadSondaggi();

        /**
         * ! Limit sondaggio title to 35 charactes
         */
        function setShortSondaggioTitle(title) {
            if (!title) {
                return "-";
            } else {
                const trimmedString = title.length > 35 ? title.substring(0, 32) + "..." : title;
                return trimmedString;
            }
        }

        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loadPrenotazioni,
            loading,
            prenotazioni,
            openDetailModal,
            openModal,
            dataPrenotazione,
            menu,
            openMenu,
            //Nuovi campi
            loadSondaggi,
            sondaggi,
            setShortSondaggioTitle,
            checkmarkCircle,
            arrowForward,
        };
    },
};
